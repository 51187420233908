import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SettingsRepository } from 'src/app/core/services/settings.repository';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public form = this.fb.group({
    location: [],
    about: [],
    phone_number: [],
    free_text: [],
    opening_hours: this.fb.array([]),
  });

  public readonly weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  get openingHours() {
    return this.form.get('opening_hours') as FormArray;
  }

  constructor(private fb: FormBuilder, private settings: SettingsRepository) {}

  ngOnInit(): void {
    this.settings.getSettings().subscribe((res) => {
      this.form.patchValue(res);
      res.opening_hours.forEach((h) =>
        this.openingHours.push(this.fb.group(h))
      );
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.form.disable();
      this.settings.setSettings(undefined, this.form.value).subscribe(
        () => this.form.enable(),
        () => this.form.enable()
      );
    }
  }
}
