import { Injectable } from '@angular/core';
import { jsPDF, jsPDFOptions } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Order, Order_items } from 'src/app/core/services/apollo/Order';

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  jsPdfOptions: jsPDFOptions = {
    orientation: 'p',
    unit: 'mm',
  };

  pdf: jsPDF;
  order: Order;
  startY = 0;

  constructor() { }

  generate(order: Order, filename: string): void {
    this.startY = 0;
    this.pdf = new jsPDF(this.jsPdfOptions);
    this.pdf.setFont('Roboto');
    this.order = order;
    this.header();
    this.startY += 20;
    this.pdf.text(
      `Rechnung zur Auftragsnummer: ${this.order.orderNumber}`,
      20,
      this.startY
    );
    const data: any[] = [];
    this.order.items.map((item: Order_items) => {
      data.push([
        item.product.texts.title,
        item.quantity.toString(),
        ((item.quantity * item.total.amount) / 100).toString(),
        (item.total.amount / 100).toString(),
      ]);
    });
    this.startY += 10;
    autoTable(this.pdf, {
      head: [['Name', 'Stück', 'Einzelpreis', 'Total']],
      foot: [
        [
          'Total',
          'davon Steuern',
          (this.order.taxes.amount / 100).toString(),
          this.order.total.amount / 100,
        ],
      ],
      body: data,
      startY: this.startY,
      headStyles: {
        fontSize: 10,
        fontStyle: 'bold',
        lineWidth: 0.5,
      },
      theme: 'plain',
      margin: 20,
      styles: {
        font: 'Roboto',
      },
    });
    this.startY += 20;
    this.footer();
    this.pdf.save(`${filename}.pdf`);
  }

  private header(): void {
    this.pdf.addImage('/assets/graphics/whali.jpg', 'JPEG', 120, 20, 100, 20);
    const date = new Date(this.order.ordered);
    this.startY += 50;
    this.pdf.setFontSize(12);
    this.pdf.text(date.toLocaleDateString('de-DE'), 195, this.startY, {
      align: 'right',
    });
    this.startY += 5;
    this.pdf.text(
      'Rechnungsnummer ' + this.order.orderNumber,
      195,
      this.startY,
      { align: 'right' }
    );
    this.address();
  }

  private footer(): void {
    const finalY = (this.pdf as any).lastAutoTable.finalY;
    this.pdf.text(
      `Zahlungsart ${this.order.paymenttype.provider.type}`,
      20,
      finalY + 20
    );
  }

  private address(): void {
    this.startY += 20;
    this.pdf.text(this.order.billingAddress.company || '', 20, this.startY);
    this.startY += 5;
    this.pdf.text(
      `${this.order.billingAddress.firstName} ${this.order.billingAddress.lastName}`,
      20,
      this.startY
    );
    this.startY += 5;
    this.pdf.text(this.order.billingAddress.addressLine || '', 20, this.startY);
    this.startY += 5;
    this.pdf.text(
      `${this.order.billingAddress.postalCode} ${this.order.billingAddress.city}`,
      20,
      this.startY
    );
  }
}
