<div class="container">
  <form [formGroup]="form" (submit)="onSubmit()">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Restaurant info</mat-panel-title>
        <mat-panel-description>
          Description, location, telephone...
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field class="d-block">
        <mat-label>Location</mat-label>
        <input matInput formControlName="location" />
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-label>About</mat-label>
        <textarea matInput formControlName="about"> </textarea>
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phone_number" />
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-label>Free text</mat-label>
        <input matInput formControlName="free_text" />
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Opening Hours</mat-panel-title>
        <mat-panel-description>
          Change your Opening hours
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div formArrayName="opening_hours">
        <div *ngFor="let alias of openingHours.controls; let i = index">
          <div [formGroupName]="i">
            <mat-form-field class="d-block">
              <mat-label>{{ weekDays[i] }}</mat-label>
              <input matInput formControlName="time" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <!-- <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Delivery Slots </mat-panel-title>
        <mat-panel-description
          >Set your delivery slots when your food will be
          delivered</mat-panel-description
        >
      </mat-expansion-panel-header>
      <p>Not yet implemented</p>
    </mat-expansion-panel> -->

    <div class="flex--end mt-3">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="form.disabled"
      >
        Save
      </button>
    </div>
  </form>
</div>
