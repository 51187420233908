import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BulkProduct } from 'src/app/declarations/bulkproduct.interface';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  bulkProducts: BulkProduct[] = [];
  csvContent: string;
  parsedCsv: string[][];
  data: any = {
    selected: 'UTF-8',
  };

  constructor(private dialogRef: MatDialogRef<ImportComponent>) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelect(input: any) {
    const component = this;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];

      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        const csvSeparator = ';';
        // @ts-ignore:disable-next-line
        const textFromFileLoaded = fileLoadedEvent.target.result;
        const txt = textFromFileLoaded;
        const csv: any[] = [];
        // @ts-ignore:disable-next-line
        const lines = txt.split('\n');
        lines.forEach((element: any) => {
          const cols: string[] = element.split(csvSeparator);
          csv.push(cols);
        });
        component.parsedCsv = csv;
        component.parsedCsv.forEach((line, index) => {
          if (index === 0) {
            return;
          }

          const titleDE = line[0];
          const slugDE = line[0].toLowerCase().replace(/\s+/g, '');
          const subtitleDE = line[1];
          const descriptionDE = line[2];
          const labelsDE = line[3]?.split(',');
          const titleEN = line[4];
          const slugEN = line[4].toLowerCase().replace(/\s+/g, '');
          const subtitleEN = line[5];
          const descriptionEN = line[6];
          const labelsEN = line[7]?.split(',');
          const amount = line[8];
          const reducedTax = line[9] ? 'swiss-tax-category:reduced' : '';

          const product: BulkProduct = {
            entity: 'PRODUCT',
            operation: 'CREATE',
            payload: {
              _id: this.uniqueId(index),
              specification: {
                type: 'SimpleProduct',
                tags: [reducedTax],
                commerce: {
                  salesUnit: 'ST',
                  salesQuantityPerUnit: '1',
                  defaultOrderQuantity: '1',
                  pricing: [
                    {
                      isTaxable: true,
                      isNetPrice: true,
                      countryCode: 'CH',
                      currencyCode: 'CHF',
                      amount: Number(amount),
                    },
                  ],
                },
                content: {
                  de: {
                    title: titleDE,
                    slug: slugDE,
                    subtitle: subtitleDE,
                    description: descriptionDE,
                    labels: labelsDE
                  },
                  en: {
                    title: titleEN,
                    slug: slugEN,
                    subtitle: subtitleEN,
                    description: descriptionEN,
                    labels: labelsEN
                  }
                }
              }
            }

          };
          component.bulkProducts.push(product);
        });
      };
      // needed to get umlaute working on import
      fileReader.readAsText(fileToRead, this.data.selected);
    }
  }

  private uniqueId(index: number): string {
    return index + Math.random().toString(16).slice(2) + (new Date()).getTime() + Math.random().toString(16).slice(2);
  }
}
