<div class="container">
  <div class="back" [routerLink]="['/products']">
    <mat-icon>navigate_before</mat-icon><mat-label>Zurück</mat-label>
  </div>
  <h1 *ngIf="product">Produkt anpassen</h1>
  <h1 *ngIf="!product">Produkt hinzufügen</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab *ngFor="let lang of languages" [label]="lang.label">
        <div fxLayout="column" class="mt-4" [formGroupName]="lang.key">
          <mat-form-field appearance="fill">
            <mat-label>Produkttitel hinzufügen</mat-label>
            <input formControlName="title" matInput />
            <mat-error *ngIf="form.get(lang.key + 'title')?.invalid"
              >Bitte gebe einen Titel ein</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Subtitel eingeben</mat-label>
            <input formControlName="subtitle" matInput />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Beschreibung eingeben</mat-label>
            <textarea formControlName="description" matInput></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill" class="row">
            <mat-label>Zutaten (comma separated)</mat-label>
            <input formControlName="ingredients" matInput />
          </mat-form-field>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-label>Komponente(n) auswählen</mat-label>
    <div fxLayout="row" class="row" fxLayoutGap="10px">
      <div *ngFor="let component of components">
        <mat-checkbox [formControlName]="component.controlName" matInput>{{
          component.label
        }}</mat-checkbox>
      </div>
    </div>
    <mat-form-field class="row" appearance="fill">
      <mat-label>Allergien</mat-label>
      <mat-select formControlName="allergens" multiple>
        <mat-option
          *ngFor="let allergen of (filter | async)?.options"
          [value]="allergen.value"
          >{{ allergen.texts.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div fxLayout="row" class="row" fxLayoutGap="10px">
      <mat-checkbox formControlName="pickup" matInput>Pick up</mat-checkbox>
      <mat-checkbox formControlName="delivery" matInput>Delivery</mat-checkbox>
    </div>
    <div fxLayout="row" class="row" fxLayoutGap="10px">
      <mat-form-field appearance="fill">
        <mat-label>Kategorie</mat-label>
        <mat-select formControlName="assortment">
          <mat-option
            *ngFor="let assortment of assortments"
            [value]="assortment._id"
            >{{ assortment.texts.title }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>Neues Bild hochladen</mat-label>
      <ngx-mat-file-input
        formControlName="file"
        placeholder="Neues Bild hochladen"
        [multiple]="false"
      ></ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="10px">
      <div *ngIf="product">
        <img class="image" [src]="product.media[0]?.file?.url" />
      </div>
      <div *ngIf="imageObjectUrl">
        <img class="image" [src]="imageObjectUrl" />
      </div>
    </div>

    <mat-form-field appearance="fill" class="row">
      <mat-label>Produktpreis eingeben</mat-label>
      <input formControlName="price" type="text" matInput />
      <mat-error *ngIf="form.controls['price'].invalid"
        >Bitte einen gültigen Preis eingeben z.B: 13.37</mat-error
      >
    </mat-form-field>
    <mat-checkbox formControlName="tax" matInput
      >Reduzierter MWST Satz? (2.5%)</mat-checkbox
    >
    <div fxLayout="row" fxLayoutGap="20px" class="row pb-4">
      <button
        *ngIf="!product"
        type="submit"
        [disabled]="form.invalid"
        mat-raised-button
        color="primary"
      >
        Produkt hinzufügen
      </button>
      <button
        *ngIf="product"
        type="submit"
        [disabled]="form.invalid"
        mat-raised-button
        color="primary"
      >
        Produkt speichern
      </button>
      <button
        *ngIf="product && product.status === 'DRAFT'"
        [disabled]="form.invalid"
        mat-raised-button
        color="primary"
        type="button"
        (click)="publish()"
      >
        Produkt veröffentlichen
      </button>
      <button
        *ngIf="product && product.status === 'ACTIVE'"
        [disabled]="form.invalid"
        mat-raised-button
        color="primary"
        type="button"
        (click)="unpublish()"
      >
        Produkt schliessen
      </button>
    </div>
  </form>
</div>
