import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  ADD_PRODUCT_MEDIA,
  CREATE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTTEXTS,
  PUBLISH_PRODUCT,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_MEDIA,
  UNPUBLISH_PRODUCT,
  UPDATE_COMMERCE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_TEXTS,
} from '../../core/services/graphql.queries';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  Products_products,
  Products_products_SimpleProduct,
} from './apollo/Products';
import {
  UpdateProductCommercePricingInput,
  UpdateProductInput,
  UpdateProductTextInput,
} from './apollo/globalTypes';
import { productTexts_translatedProductTexts } from './apollo/productTexts';

@Injectable({
  providedIn: 'root',
})
export class ProductRepository {
  constructor(private apollo: Apollo) { }

  setProduct(product: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: CREATE_PRODUCT,
        variables: {
          product,
        },
      })
      .pipe(map((result: any) => result?.data.createProduct));
  }

  removeProduct(productId: string): Promise<any> {
    return this.apollo
      .mutate({
        mutation: REMOVE_PRODUCT,
        variables: {
          productId,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .toPromise();
  }

  publishProduct(productId: string): Promise<any> {
    return this.apollo
      .mutate({
        mutation: PUBLISH_PRODUCT,
        variables: {
          productId,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .toPromise();
  }

  unpublishProduct(productId: string): Promise<any> {
    return this.apollo
      .mutate({
        mutation: UNPUBLISH_PRODUCT,
        variables: {
          productId,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .toPromise();
  }

  getProduct(productId: string): Observable<Products_products_SimpleProduct> {
    return this.apollo
      .watchQuery({
        query: GET_PRODUCT,
        variables: {
          productId,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.product)
      );
  }

  getProductTexts(
    productId: string
  ): Observable<productTexts_translatedProductTexts[]> {
    return this.apollo
      .watchQuery({
        query: GET_PRODUCTTEXTS,
        variables: {
          productId,
        },
      })
      .valueChanges.pipe(
        map(
          (result: ApolloQueryResult<any>) =>
            result?.data.translatedProductTexts
        )
      );
  }

  addProductMedia(productId: string, media: any): Promise<any> {
    return this.apollo
      .mutate({
        mutation: ADD_PRODUCT_MEDIA,
        variables: {
          productId,
          media,
        },
        context: {
          useMultipart: true,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .pipe(take(1))
      .toPromise();
  }

  removeProductMedia(productMediaId: any): Promise<any> {
    return this.apollo
      .mutate({
        mutation: REMOVE_PRODUCT_MEDIA,
        variables: {
          productMediaId,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .pipe(take(1))
      .toPromise();
  }

  updateProductTexts(
    productId: string,
    texts: UpdateProductTextInput
  ): Promise<any> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUCT_TEXTS,
        variables: {
          productId,
          texts,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .pipe(take(1))
      .toPromise();
  }

  updateProductCommerce(
    productId: string,
    commerce: UpdateProductCommercePricingInput
  ): Promise<any> {
    return this.apollo
      .mutate({
        mutation: UPDATE_COMMERCE,
        variables: {
          productId,
          commerce: { pricing: [commerce] },
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .pipe(take(1))
      .toPromise();
  }

  updateProduct(productId: string, product: UpdateProductInput): Promise<any> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUCT,
        variables: {
          productId,
          product,
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      })
      .pipe(take(1))
      .toPromise();
  }

  getProducts(): Observable<Products_products[]> {
    return this.apollo
      .watchQuery({
        query: GET_PRODUCTS,
        variables: { limit: 100 },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.products)
      );
  }
}
