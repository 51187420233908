import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_FILTER } from './graphql.queries';
import { ApolloQueryResult } from '@apollo/client/core';
import { filters_filters } from './apollo/filters';

@Injectable({
  providedIn: 'root',
})
export class FilterRepository {
  constructor(private apollo: Apollo) {}

  getFilter(): Observable<filters_filters> {
    return this.apollo
      .watchQuery({
        query: GET_FILTER,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.filters[0])
      );
  }
}
