import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { LayoutComponent } from './core/layout/layout.component';
import { RedirectIfLoggedIn } from './shared/guards/redirect.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  // },
  {
    path: 'login',
    canActivate: [RedirectIfLoggedIn],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AngularTokenService],
    children: [
      // {
      //   path: 'home',
      //   loadChildren: () =>
      //     import('./pages/home/home.module').then((m) => m.HomeModule),
      // },
      {
        path: 'products',
        loadChildren: () =>
          import('./pages/products/products.module').then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: 'assortment',
        loadChildren: () =>
          import('./pages/assortment/assortment.module').then(
            (m) => m.AssortmentModule
          ),
      },
      {
        path: 'pickup',
        loadChildren: () =>
          import('./pages/pickup/pickup.module').then((m) => m.PickupModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/orders/orders.module').then((m) => m.OrdersModule),
      },
      // {
      //   path: 'user',
      //   loadChildren: () =>
      //     import('./features/user/user.module').then((m) => m.UserModule),
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
