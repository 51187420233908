import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RedirectIfLoggedIn implements CanActivate {
  constructor(
    private authenticationService: AngularTokenService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authenticationService.userSignedIn()) {
      return this.router.parseUrl('/orders');
    } else {
      return true;
    }
  }
}
