<ng-container *ngIf="order$ | async as order">
  <div class="order-detail container">
    <div class="back-button" [routerLink]="['/orders']">
      <mat-icon>navigate_before</mat-icon><mat-label>Zurück</mat-label>
    </div>
    <div class="flex--space-between mx-2 my-4">
      <div class="d-flex flex-row">
        <h1 class="mb-0 mr-4">
          Bestellung details <b>#{{ order.orderNumber }}</b>
        </h1>
        <mat-chip-list>
          <mat-chip color="secondary" selected="true">{{
            order.status
          }}</mat-chip>
        </mat-chip-list>
      </div>
      <div>
        <button
          class="px-2 mr-4"
          (click)="handleCancelOrder(order)"
          [disabled]="order.status !== 'OPEN'"
          mat-raised-button
        >
          <mat-icon
            class="mr-1"
            [color]="order.status !== 'OPEN' ? '' : 'primary'"
            >cancel</mat-icon
          >
          <span>Cancel order</span>
        </button>
        <button
          class="px-2"
          (click)="handleDownloadReceipt(order)"
          mat-raised-button
        >
          <mat-icon class="mr-1" color="primary">download</mat-icon>Download
          receipt
        </button>
      </div>
    </div>

    <mat-card class="content mt-4">
      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="50">
          <dx-data-grid
            id="gridContainer"
            [dataSource]="order.items"
            [remoteOperations]="false"
            [allowColumnReordering]="false"
            [rowAlternationEnabled]="true"
            [showBorders]="true"
          >
            <dxo-sorting mode="none"> </dxo-sorting>
            <dxo-scrolling mode="standard" useNative="true"></dxo-scrolling>
            <dxi-column
              caption="Product name"
              dataField="product.texts.title"
              dataType="string"
            ></dxi-column>
            <dxi-column
              caption="Menge"
              dataField="quantity"
              dataType="number"
              width="70"
            ></dxi-column>
            <dxi-column
              caption="Aktionen"
              dataField="_id"
              cellTemplate="cellTemplateActions"
              width="50"
            ></dxi-column>

            <div *dxTemplate="let data of 'cellTemplateActions'">
              <div class="flex--center">
                <mat-icon
                  class="pointer"
                  color="primary"
                  (click)="handleCancelOrderItem(data.value)"
                  >cancel</mat-icon
                >
              </div>
            </div>
          </dx-data-grid>
        </div>

        <div fxFlex="50">
          <div fxLayout="row">
            <div fxFlex="33">
              <mat-form-field appearance="fill">
                <mat-label>Preis brutto</mat-label>
                <input
                  matInput
                  value="{{ order.total.amount | price }}"
                  disabled
                />
              </mat-form-field>
            </div>
            <div fxFlex="33">
              <mat-form-field appearance="fill">
                <mat-label>Preis netto</mat-label>
                <input
                  matInput
                  value="{{ order.total.amount - order.taxes.amount | price }}"
                  disabled
                />
              </mat-form-field>
            </div>
            <div fxFlex="33">
              <mat-form-field appearance="fill">
                <mat-label>MWST.</mat-label>
                <input
                  matInput
                  value="{{ order.taxes.amount | price }}"
                  disabled
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="content mt-4 d-none">
      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="50">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Customer comments</mat-label>
            <textarea matInput [mat-autosize]="true" disabled>{{
              order.meta?.note || "N/A"
            }}</textarea>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <div fxLayout="row">
            <div fxFlex="50">
              <div fxLayout="column" fxLayoutAlign="start start">
                <mat-form-field fxFlexFill appearance="fill">
                  <mat-label>Customer</mat-label>
                  <input matInput value="{{ order.user.username }}" disabled />
                </mat-form-field>

                <mat-form-field fxFlexFill appearance="fill">
                  <mat-label>Ordered</mat-label>
                  <input
                    matInput
                    value="{{ order.ordered | date: dateFormat }}"
                    disabled
                  />
                </mat-form-field>
              </div>
            </div>

            <div fxFlex="50">
              <mat-form-field fxFlexFill appearance="fill">
                <mat-label>Company</mat-label>
                <input
                  matInput
                  value="{{ order.meta?.company || 'N/A' }}"
                  disabled
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
