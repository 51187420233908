<h2 mat-dialog-title>Importiere CSV Datei</h2>
<mat-dialog-content>
  <div class="dialog-container mat-typography">
    <input
      type="file"
      (change)="onFileSelect($event.target)"
      name="myfile"
      accept=".csv"
    />
    <mat-form-field>
      <mat-select [(value)]="data.selected">
        <mat-option [value]="'UTF-8'">UTF-8</mat-option>
        <mat-option [value]="'Windows-1252'">Windows-1252</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="bulkProducts">
    OK
  </button>
</mat-dialog-actions>
