import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BulkProduct } from 'src/app/declarations/bulkproduct.interface';
import { BULK_IMPORT, GET_PRODUCTS } from './graphql.queries';

@Injectable({
    providedIn: 'root',
})
export class BulkImportRepository {

    constructor(private apollo: Apollo) { }

    addProducts(events: BulkProduct[]): Observable<any> {
        return this.apollo
            .mutate({
                mutation: BULK_IMPORT,
                variables: {
                    input: {
                        events,
                    }
                }, refetchQueries: [{ query: GET_PRODUCTS }],
            });
    }
}
