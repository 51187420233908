// apollo client:codegen "./apollo"
// --localSchemaFile="graphql-schema.json"
// --endpoint="https://uptown-basel-dev-shop-api.upt.panter.cloud/graphql"
// --header="authorization: 'Bearer 13c9731467cc6527cbc85f7eeb211517de28590a2af'"
// --target="typescript"
// --addTypename --includes=src/app/core/services/graphql.queries.ts
// --tagName=gql

import { gql } from 'apollo-angular';

export const ORDER_FRAGMENT = gql`
  fragment Order on Order {
    _id
    total {
      amount
    }
    billingAddress {
      firstName
      lastName
      company
      postalCode
      countryCode
      regionCode
      city
      addressLine
      addressLine2
    }
    orderNumber
    status
    ordered
    fullfilled
    meta
    user {
      email
      username
    }
    paymenttype: payment {
      provider {
        type
      }
    }
    items {
      _id
      product {
        _id
        tags
        texts {
          title
          subtitle
          description
          labels
          vendor
        }
        media {
          _id
          file {
            url
          }
        }
        ... on SimpleProduct {
          catalogPrice {
            _id
            amount
          }
        }
      }
      quantity
      total(category: ITEM) {
        amount
        currency
      }
    }
    payment: total(category: PAYMENT) {
      amount
      currency
    }
    delivery: total(category: DELIVERY) {
      amount
      currency
    }
    taxes: total(category: TAXES) {
      amount
      currency
    }
    discounts: total(category: DISCOUNTS) {
      amount
      currency
    }
    net: total {
      amount
      currency
    }
  }
`;

export const ORDER_SHORT_FRAGMENT = gql`
  fragment OrderShort on Order {
    status
    orderNumber
    ordered
    confirmed
    fullfilled
    items {
      product {
        _id
        texts {
          title
        }
      }
      quantity
    }
    total {
      amount
    }
  }
`;

export const ASSORTMENT_FRAGMENT = gql`
  fragment Assortment on Assortment {
    _id
    productAssignments {
      _id
      product {
        _id
        texts {
          title
        }
      }
    }
    texts {
      _id
      locale
      title
      description
      subtitle
    }
    created
  }
`;

export const GET_ORDERS = gql`
  query Orders($limit: Int, $offset: Int, $includeCarts: Boolean) {
    orders(limit: $limit, offset: $offset, includeCarts: $includeCarts) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_DELIVERY_PROVIDER = gql`
  query deliveryProvider($deliveryProviderId: ID!) {
    deliveryProvider(deliveryProviderId: $deliveryProviderId) {
      isActive
      type
      configuration
    }
  }
`;

export const UPDATE_DELIVERY_PROVIDER = gql`
  mutation updateDeliveryProvider(
    $deliveryProvider: UpdateProviderInput!
    $deliveryProviderId: ID!
  ) {
    updateDeliveryProvider(
      deliveryProvider: $deliveryProvider
      deliveryProviderId: $deliveryProviderId
    ) {
      _id
    }
  }
`;

export const REMOVE_ORDER = gql`
  mutation removeOrder($orderId: ID!) {
    removeOrder(orderId: $orderId) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_ORDER = gql`
  query Order($orderId: ID!) {
    order(orderId: $orderId) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_PRODUCTS = gql`
  query Products($limit: Int) {
    products(includeDrafts: true, limit: $limit) {
      _id
      assortmentPaths {
        links {
          assortmentId
          assortmentTexts {
            title
          }
        }
      }
      ... on SimpleProduct {
        catalogPrice {
          _id
          amount
        }
      }
      status
      ... on ConfigurableProduct {
        products {
          _id
          ... on SimpleProduct {
            catalogPrice {
              amount
            }
          }
        }
        variations {
          texts {
            title
          }
        }
      }
      ... on BundleProduct {
        bundleItems {
          product {
            ... on SimpleProduct {
              catalogPrice {
                _id
                amount
              }
            }
          }
        }
      }
      tags
      texts {
        title
        subtitle
        description
        labels
        vendor
      }
      created
      published
      updated
      media {
        _id
        file {
          url
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query Product($productId: ID!) {
    product(productId: $productId) {
      _id
      assortmentPaths {
        links {
          assortmentId
          assortmentTexts {
            title
          }
        }
      }
      tags
      ... on SimpleProduct {
        catalogPrice {
          _id
          amount
        }
      }
      status
      texts {
        title
        subtitle
        description
        vendor
        brand
        labels
      }
      media {
        _id
        file {
          url
        }
      }
    }
  }
`;

export const GET_PRODUCTTEXTS = gql`
  query productTexts($productId: ID!) {
    product(productId: $productId) {
      _id
      status
    }
    languages {
      _id
      isoCode
      isActive
      isBase
      name
    }
    translatedProductTexts(productId: $productId) {
      _id
      locale
      title
      subtitle
      slug
      description
      vendor
      brand
      labels
    }
  }
`;

export const ADD_ASSORTMENT = gql`
  mutation createAssortment($assortment: CreateAssortmentInput!) {
    createAssortment(assortment: $assortment) {
      ...Assortment
    }
  }
  ${ASSORTMENT_FRAGMENT}
`;

export const REMOVE_ASSORTMENT = gql`
  mutation removeAssortment($assortmentId: ID!) {
    removeAssortment(assortmentId: $assortmentId) {
      _id
    }
  }
`;

export const GET_ASSORTMENTS = gql`
  query Assortments {
    assortments {
      ...Assortment
    }
  }
  ${ASSORTMENT_FRAGMENT}
`;

export const UPDATE_ASSORTMENT_TEXTS = gql`
  mutation updateAssortmentTexts(
    $assortmentId: ID!
    $texts: [UpdateAssortmentTextInput!]!
  ) {
    updateAssortmentTexts(assortmentId: $assortmentId, texts: $texts) {
      _id
    }
  }
`;
// export const GET_PRODUCT = gql`
//   query Product($productId: ID!) {
//     product(productId: $id) {
//       ...Product
//     }
//   }
//   ${PRODUCT}
// `;

export const ADD_ASSORTMENT_PRODUCT = gql`
  mutation addAssortmentProduct(
    $assortmentId: ID!
    $productId: ID!
    $tags: [String!]
  ) {
    addAssortmentProduct(
      assortmentId: $assortmentId
      productId: $productId
      tags: $tags
    ) {
      _id
      sortKey
      tags
      meta
    }
  }
`;

export const GET_FILTER = gql`
  query filters {
    filters {
      _id
      options {
        value
        texts(forceLocale: "de") {
          locale
          title
          subtitle
        }
      }
    }
  }
`;

export const UPDATE_COMMERCE = gql`
  mutation updateCommerce(
    $productId: ID!
    $commerce: UpdateProductCommerceInput!
  ) {
    updateProductCommerce(productId: $productId, commerce: $commerce) {
      _id
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($productId: ID!, $product: UpdateProductInput!) {
    updateProduct(productId: $productId, product: $product) {
      _id
    }
  }
`;

export const UPDATE_PRODUCT_TEXTS = gql`
  mutation updateProductTexts(
    $productId: ID!
    $texts: [UpdateProductTextInput!]!
  ) {
    updateProductTexts(productId: $productId, texts: $texts) {
      _id
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($product: CreateProductInput!) {
    createProduct(product: $product) {
      _id
    }
  }
`;

export const REMOVE_PRODUCT = gql`
  mutation removeProduct($productId: ID!) {
    removeProduct(productId: $productId) {
      _id
    }
  }
`;

export const PUBLISH_PRODUCT = gql`
  mutation publishProduct($productId: ID!) {
    publishProduct(productId: $productId) {
      _id
    }
  }
`;

export const UNPUBLISH_PRODUCT = gql`
  mutation unpublishProduct($productId: ID!) {
    unpublishProduct(productId: $productId) {
      _id
    }
  }
`;

export const ADD_PRODUCT_MEDIA = gql`
  mutation addProductMedia($productId: ID!, $media: Upload!) {
    addProductMedia(productId: $productId, media: $media) {
      _id
      tags
      sortKey
    }
  }
`;

export const REMOVE_PRODUCT_MEDIA = gql`
  mutation removeProductMedia($productMediaId: ID!) {
    removeProductMedia(productMediaId: $productMediaId) {
      _id
      tags
      sortKey
    }
  }
`;

export const GET_PRICING = gql`
  query ProductPricing($productId: ID!) {
    productCatalogPrices(productId: $productId) {
      amount
      currency {
        isoCode
      }
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      _id
      email
      username
      isEmailVerified
      isGuest
      isInitialPassword
      name
      roles
      tags
      orders(includeCarts: true) {
        ...Order
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const BULK_IMPORT = gql`
  mutation addWork($input: JSON) {
    addWork(type: BULK_IMPORT, input: $input, retries: 0, priority: 10) {
      _id
    }
  }
`;
