import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestaurantSettings } from 'src/app/declarations/settings.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsRepository {
  constructor(private http: HttpClient) {}

  setSettings(
    restaurantID = 1,
    settings: RestaurantSettings
  ): Observable<RestaurantSettings> {
    return this.http.patch<RestaurantSettings>(
      `${environment.apiDomain}/restaurant_settings/${restaurantID}`,
      { ...settings }
    );
  }

  getSettings(restaurantID = 1): Observable<RestaurantSettings> {
    return this.http.get<RestaurantSettings>(
      `${environment.apiDomain}/restaurant_settings/${restaurantID}`
    );
  }
}
