import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/core/services/apollo/Order';
import { OrderRepository } from 'src/app/core/services/order.repository';
import { Observable } from 'rxjs';
import { DATE_FORMAT } from 'src/app/declarations/constants';
import { OrdersService } from '../orders.service';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailComponent implements OnInit {
  id: string;
  order$: Observable<Order>;

  dateFormat = DATE_FORMAT;

  constructor(
    private route: ActivatedRoute,
    private orderRepository: OrderRepository,
    private ordersService: OrdersService,
    private pdfGeneratorService: PdfGeneratorService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.order$ = this.orderRepository.findOne(this.id);
  }

  handleCancelOrderItem(value: any): void {
    // TODO
    console.log(value);
    window.alert('TODO');
  }

  handleCancelOrder(order: Order): void {
    this.ordersService.cancelOrder(order._id);
  }

  handleDownloadReceipt(order: Order): void {
    this.pdfGeneratorService.generate(order, `receipt_${order.orderNumber}`);
  }
}
